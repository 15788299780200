import React from 'react';

export const Home = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h1 className="text-white text-center mt-5">Ranking</h1>
                </div>
            </div>
        </div>
    );
}