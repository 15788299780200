import React from 'react';
import { UserService } from '../UserService';
import card1 from '../images/freecity-citizen.png'; 
import card2 from '../images/freecity-home.png'; 

export const Page2 = () => {

    const onHandleSendWax = () => {
        UserService.session.signTransaction(
            {
                actions: [{
                    account: 'eosio.token',
                    name: 'transfer',
                    authorization: [{
                        actor: UserService.authName,
                        permission: 'active'
                    }],
                    data: {
                        from: UserService.authName,
                        to: '2ivi.wam',
                        quantity: '1.00000000 RMX',
                        memo: 'This works!'
                    }
                }]
            },
            {
                blocksBehind: 3,
                expireSeconds: 30
            }
        ).then((response) => {
            if(response.status === 'executed') {
                UserService.getBalance();
            }
        });

        // Refund balance
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-12"><center>
                <h5 className="text-white text-center mt-5">For phase one of the game, we can generate nft cards that are rarer, more valuable and with more and more votes. The more votes you get, the more you earn </h5>
                <h3 className="text-white text-center mt-5">#1 - Generate Home Card</h3>
                <h5 className="text-white text-center mt-5">Burn 5 Citizen Card (5 votes) to generate 1 Home Card with (100 votes).</h5>
                <table width="200" border="0">
  <tbody align="center">
    <tr>
      <td><img src={card1} alt="Card 1" width="125" /></td>
      <td><h1 className="text-white text-center mt-5">X</h1></td>
      <td><img src={card2} alt="Card 2" width="125" /></td>
    </tr>
    <tr>
      <td><h3 className="text-white text-center mt-5">.</h3></td>
      <td><button className="btn btn-success btn-lg" onClick={onHandleSendWax}>Send</button></td>
      <td><h3 className="text-white text-center mt-5">.</h3></td>
    </tr>
  </tbody>
</table>

<h3 className="text-white text-center mt-5">NEXT</h3>
                </center>
                </div>
                <div className="col-12 text-center">
                   
                </div>
            </div>
        </div>
    );
}
